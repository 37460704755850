import "./Hashtag.scss";

function Hashtag() {
  return (
    <b className="hashtag">
      #
    </b>
  );
}


export default Hashtag;
