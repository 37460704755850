import './Header.scss';

function Header() {
  return (
    <div className="titleName">
      <h1 className="header1 title">BattleDrones</h1>
    </div>
  );
}

export default Header;
